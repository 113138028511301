import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import styles from "./CookieManager.module.css";

const CookieManager: React.FC = () => {
  const [isSettings, setIsSettings] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("ne");
  const [isVisible, setIsVisible] = useState<boolean>(() => {
    // Initialize to true if no consent cookie is present
    return Cookies.get("user-consent") === undefined;
  });

  function addGAScript() {
    // Preveri, ce ze obstaja ga-script
    if (!document.getElementById("ga-script")) {
      // Kreira async skripto
      var gaScript = document.createElement("script");
      gaScript.id = "ga-script";
      gaScript.async = true;
      gaScript.src = "https://www.googletagmanager.com/gtag/js?id=G-Y9NMXY5X4P";

      // Dodamo skripto med glavo in telesom
      document.head.insertAdjacentElement("afterend", gaScript);

      // Kreiramo inline skriptoza dataLayer in gtag inicializacijo
      var inlineScript = document.createElement("script");
      inlineScript.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Y9NMXY5X4P');
            `;

      // Dodamo skripto med glavo in telesom
      document.head.insertAdjacentElement("afterend", inlineScript);
    }
  }

  /* Preveri piskotke ob vstopu v spletno stran */
  useEffect(() => {
    // Preverimo, ce je uporabnik privolil v piskotke
    if (Cookies.get("user-consent") === "da") {
      addGAScript();
    }
  }, []);

  const handleAcceptAll = () => {
    Cookies.set("user-consent", "da", { expires: 365 });
    setIsVisible(false);
    addGAScript();
  };

  const handleSaveSettings = () => {
    Cookies.set("user-consent", selectedOption, { expires: 365 });
    setIsSettings(false);
    setIsVisible(false);

    // Preverimo, ce je uporabnik privolil v piskotke
    if (selectedOption === "da") {
      addGAScript();
    }
  };

  if (!isVisible) return null;

  return (
    <div className={styles.cookie_manager}>
      {isSettings ? (
        <div className={styles.settings_wrapper}>
          <div>
            <div className={styles.settings_text}>
              <h2>Funkcionalni:</h2>
              <p>Nujno potrebni za delovanje spletne strani.</p>
            </div>

            <div className={styles.settings_text}>
              <h2>Statistika:</h2>
              <p>
                Uporabljajo se izključno za beleženje obiskov spletne strani in
                socialnih omrežij. Ne zbirajo informacij, preko katerih bi lahko
                identificirali uporabnika.
              </p>
            </div>
          </div>

          <div className={styles.buttons_wrapper_settings}>
            <div className={`${styles.button_settings} ${styles.button_da}`}>
              DA
            </div>
            <div className={styles.option_button}>
              <div
                className={`${styles.button_settings} ${styles.option_ne} ${selectedOption === "ne" ? styles.selected : ""}`}
                onClick={() => setSelectedOption("ne")}
              >
                NE
              </div>
              <div
                className={`${styles.button_settings} ${styles.option_da} ${selectedOption === "da" ? styles.selected : ""}`}
                onClick={() => setSelectedOption("da")}
              >
                DA
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.consent_wrapper}>
          <div>
            <h2>Soglasje za piškotke</h2>
            <p>
              Spletna stran uporablja in shranjuje piškotke (angl. Cookies).
            </p>
            <p>
              Piškotke uporabljamo za analizo prometa spletne strani in
              socialnih omrežij.
            </p>
            <p>Piškotkov ne uporabljamo za komercialne namene trženja.</p>
            <p>
              S klikom na gumb „IZBERI VSE IN ZAPRI“ soglašate z uporabo
              piškotkov.
            </p>
          </div>
        </div>
      )}

      <div className={styles.buttons_wrapper}>
        <button
          className={`${styles.button} ${styles.button_izberi_vse}`}
          onClick={handleAcceptAll}
        >
          IZBERI VSE IN ZAPRI
        </button>
        <button
          className={`${styles.button} ${styles.button_nastavitve}`}
          onClick={isSettings ? handleSaveSettings : () => setIsSettings(true)}
        >
          {isSettings ? "SHRANI NASTAVITVE" : "SPREMENI NASTAVITVE"}
        </button>
      </div>
    </div>
  );
};

export default CookieManager;
