import React from "react";
import styles from "./Reference.module.css";

const Reference: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2>
        Kaj drugi
        <br />
        pravijo o meni?
      </h2>

      <div className={styles.reference}>
        <div className={styles.client}>
          <h3>Dragana Čosič</h3>
          <h4>Vodja marketinga, Sparkasse Pay d.o.o.</h4>
          <p>
            Sebastjan Rutart je izjemno profesionalen, dosleden in predan
            delavec, s katerim imam izključno pozitivne izkušnje kot njegova
            vodja od leta 2014. Vedno je zagotavljal najvišjo kakovost
            predstavitve blagovne znamke in bil pripravljen delati tudi izven
            delovnega časa. Njegova pomoč sodelavcem in pripravljenost reševati
            težave sta bila neprecenljiva. Na Sebastjana sem se vedno lahko
            zanesla zaradi njegove poštenosti in zanesljivosti. Prepričana sem,
            da bo vsak delodajalec z njim pridobil vestnega delavca, veliko
            znanja in kreativnosti.
          </p>
        </div>
        <div className={styles.client}>
          <h3>Aljaž Podlogar</h3>
          <h4>Direktor in partner, Rubikon GRAL</h4>
          <p>
            S Sebastjanom je bilo vedno profesionalno sodelovanje. Lahko
            pohvalim odzivnost in natančnost. Doslednost pri grafičnih podobah
            je bila vedno njegova vrlina. Izredno pa smo bili zadovoljni tudi s
            kreativnim delom in spoštovanjem dogovorov. Za piko na i pa bi dodal
            njegov humor, ki nam je pri odgovorih vedno polepšal dan :).
          </p>
        </div>
        <div className={styles.client}>
          <h3>Mojca Javornik, prodajna managerka</h3>
          <h4>Spark Promotions, promocijska in poslovna darila</h4>
          <p>
            S Sebastjanom sem večkrat sodelovala na projektih kot izvajalka za
            tisk grafičnih materialov za Erste Card d.o.o. Najpogosteje je šlo
            za tiskane brošure, zastavice, roll-upe, etikete in podobne tiskane
            materiale, včasih pa tudi za druge promocijske izdelke s tiskom.
          </p>
          <p>
            Grafične podobe, ki jih je pripravil Sebastjan, so bile natančno
            izdelane z vsemi opisi in detajli za izvedbo. Pri njem se res pozna,
            da ima ogromno izkušenj, zato je njegov odziv tudi pri dodatnih
            vprašanjih hiter in natančen. Na Sebastjana se lahko zaneseš, da bo
            poznal specifiko izdelka, ki ga je oblikoval, in da bo predlagal
            tudi ustrezne materiale za tisk. Končni izdelki bodo zato izgledali
            točno tako, kot je bilo mišljeno in dogovorjeno. Priporočam ga vsem,
            ki potrebujete zanesljivega grafičnega oblikovalca z veliko
            izkušnjami in široko mrežo poznanstev tudi na drugih področjih,
            povezanih z marketingom. Komunikacija in sodelovanje z njim mi je
            vedno v veselje.
          </p>
        </div>
        <div className={styles.client}>
          <h3>Anej Ferko</h3>
          <h4>CREATIVE TEAM</h4>
          <p>
            Sebastjan je človek na katerega se res lahko zaneseš. Pri svojem
            delu je profesionalen, ima občutek za delo s premium blagovnimi
            znamkami in je pravi kreativec s srcem. Ko začuti znamko zna iz nje
            narediti ogromno.
          </p>
        </div>
        <div className={styles.client}>
          <h3>Teja Ucman</h3>
          <p>
            Sebastjan je mojster za tisk. S kreativnim pristopom zna narediti
            res dobre ideje, ki presegajo okvir oblikovalca. In tako lahko
            znamka izstopa.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Reference;
