import React, { useEffect, useRef, useState } from "react";
import * as Asset from "utils/Assets";
import styles from "./home.module.css";

import Header from "components/Home/Header/Header";
import Footer from "components/Home/Footer/Footer";
import Reference from "components/Home/Reference/Reference";
import Contact from "components/Home/Contact/Contact";
import SocialMedia from "components/Home/SocialMedia/SocialMedia";
import Experience from "components/Home/Experience/Experience";

import Button from "components/Button/Button";

import CookieManager from "components/CookieManager/CookieManager";
import ImageGallery from "components/ImageGallery/ImageGallery";
import ModalImageGallery from "components/ModalImageGallery/ModalImageGallery";

const Home: React.FC = () => {
  const puscicaRef = useRef<HTMLImageElement | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [isModal, setIsModal] = useState(false);
  const [useGalleryImages, setUseGalleryImages] = useState<string[]>([]);

  const imagesGallery1 = [
    Asset.gallery1_1,
    Asset.gallery1_2,
    Asset.gallery1_3,
    Asset.gallery1_4,
    Asset.gallery1_5,
    Asset.gallery1_6,
    Asset.gallery1_7,
    Asset.gallery1_8,
    Asset.gallery1_9,
    Asset.gallery1_10,
    Asset.gallery1_11,
    Asset.gallery1_12,
    Asset.gallery1_13,
    Asset.gallery1_14,
    Asset.gallery1_15,
    Asset.gallery1_16,
    Asset.gallery1_17,
    Asset.gallery1_18,
  ];

  const imagesGallery2 = [
    Asset.gallery2_1,
    Asset.gallery2_2,
    Asset.gallery2_3,
    Asset.gallery2_4,
    Asset.gallery2_5,
    Asset.gallery2_6,
    Asset.gallery2_7,
    Asset.gallery2_8,
    Asset.gallery2_9,
    Asset.gallery2_10,
    Asset.gallery2_11,
    Asset.gallery2_12,
    Asset.gallery2_13,
    Asset.gallery2_14,
    Asset.gallery2_15,
    Asset.gallery2_16,
    Asset.gallery2_17,
    Asset.gallery2_18,
    Asset.gallery2_19,
    Asset.gallery2_20,
    Asset.gallery2_21,
  ];

  const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Update the state initially based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const btnNarociSeElement = document.querySelector(`.${styles.header}`);

    if (btnNarociSeElement) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (puscicaRef.current) {
            if (entry.isIntersecting) {
              puscicaRef.current.classList.remove(styles.visible);
            } else {
              puscicaRef.current.classList.add(styles.visible);
            }
          }
        },
        { threshold: 0 }
      );

      observer.observe(btnNarociSeElement);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  // Disable screen moving when scrollbar disappears
  useEffect(() => {
    if (!isModal) return;

    // Get initial body padding and scrollbar width
    const originalStyle = window.getComputedStyle(document.body);
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    const originalPadding =
      parseInt(originalStyle.getPropertyValue("padding-right")) || 0;

    // Save original overflow
    const originalOverflow = document.body.style.overflow;

    // Apply scroll lock
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${originalPadding + scrollBarWidth}px`;

    // Cleanup function
    return () => {
      document.body.style.overflow = originalOverflow;
      document.body.style.paddingRight = `${originalPadding}px`;
    };
  }, [isModal]);

  const toggleModalGallery = (images: string[]) => {
    setIsModal(true);
    setUseGalleryImages(images);
  };

  return (
    <>
      <div className={styles.home}>
        <div className={styles.header}>
          <Header />
          <div className={styles.button_naroci}>
            <Button
              text={"Za posvet"}
              image={Asset.naroci_arrow_large}
              subject={"Za posvet"}
            />
          </div>
        </div>

        <main className={styles.main}>
          <section className={styles.graficno_container}>
            <h2>
              Grafično
              <br />
              oblikovanje
            </h2>
            <p>
              za posameznike, društva in podjetja. Skupaj najdimo nove
              razburljive načine komuniciranja vaše identitete v digitalnem in
              družbenem prostoru.
            </p>
          </section>

          <section className={styles.storitve_container}>
            <div className={styles.storitve}>
              <h2>Storitve</h2>
              <p className={styles.list_item}>
                oblikovanje in urejanje spletnih strani
              </p>
              <p className={styles.list_item}>oblikovanje bannerjev za splet</p>
              <p className={styles.list_item}>
                oblikovanje in priprava video oglasov za družbena omrežja
              </p>
              <p className={styles.list_item}>
                grafično oblikovanje na daljavo
              </p>
              <p className={styles.list_item}>
                oblikovanje celostne grafične podobe
              </p>
              <p className={styles.list_item}>oblikovanje logotipa</p>
              <p className={styles.list_item}>
                oblikovanje vizitk za tisk ali digitalne s QR-KODO
              </p>
              <p className={styles.list_item}>
                oblikovanje brošur, letakov, tiskovin
              </p>
              <p className={styles.list_item}>oblikovanje embalaže</p>
              <p className={styles.list_item}>
                oblikovanje in prelom strani za knjige, kataloge, magazine ...
              </p>
              <p className={styles.list_item}>
                oblikovanje in priprava sejemskih in prireditvenih prostorov
              </p>
              <p className={styles.list_item}>
                oblikovanje majic po naročilu, personalizirane majice
              </p>
            </div>
            {isMobile ? (
              <div className={styles.image_gallery_storitve}>
                <ImageGallery images={imagesGallery1} />
              </div>
            ) : (
              <div className={`${styles.banner_container} ${styles.animate}`}>
                <img
                  className={styles.storitve_banner}
                  src={Asset.gallery1_1}
                  alt="Rutart majca print"
                  onClick={() => toggleModalGallery(imagesGallery1)}
                />
              </div>
            )}
          </section>

          <section className={styles.koristi_container}>
            {isMobile ? (
              <div className={styles.image_gallery_koristi}>
                <ImageGallery images={imagesGallery2} />
              </div>
            ) : (
              <div className={`${styles.banner_container} ${styles.animate}`}>
                <img
                  className={styles.koristi_banner}
                  src={Asset.gallery2_1}
                  alt="Banner koristi"
                  onClick={() => toggleModalGallery(imagesGallery2)}
                />
              </div>
            )}
            <div className={styles.koristi}>
              <h2>Koristi</h2>
              <p>
                Na trgu, kjer je veliko podobnih izdelkov in storitev, lahko
                privlačna vizualna podoba ponudi konkurenčno prednost.
                Potrošniki so bolj nagnjeni k izbiri izdelka ali podjetja, ki
                jim vizualno ugaja.
              </p>
            </div>
          </section>

          <Experience />
          <Reference />
          <Contact />
          <SocialMedia />
        </main>

        <Footer />
        <img
          className={styles.scroll_puscica}
          ref={puscicaRef}
          src={Asset.Puscica}
          alt="Scroll to top"
          onClick={ScrollToTop}
        />
      </div>
      <CookieManager />
      {isModal && (
        <ModalImageGallery
          images={useGalleryImages}
          onClose={() => setIsModal(false)}
        />
      )}
    </>
  );
};

export default Home;
