import React from "react";
import styles from "./Button.module.css";

interface Props {
  text: string;
  image: string;
  subject: string;
}

const Button: React.FC<Props> = ({ text, image, subject }) => {
  const openMailClient = (subject: string) => {
    const recipient = "oblikovanje@rutart.si";
    const body = "";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <button className={styles.button} onClick={() => openMailClient(subject)}>
      <p>{text}</p>
      <img src={image} alt={`Button ${text}`} />
    </button>
  );
};

export default Button;
