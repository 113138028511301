import React, { useState, useEffect } from "react";
import styles from "./ModalImageGallery.module.css";
import { gallery_left, gallery_right, gallery_close } from "../../utils/Assets";

interface ModalImageGalleryProps {
  images: string[];
  onClose: () => void;
}

const ModalImageGallery: React.FC<ModalImageGalleryProps> = ({
  images,
  onClose,
}) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPortrait, setIsPortrait] = useState<boolean>(
    window.matchMedia("(orientation: portrait)").matches
  );

  // Handle orientation change
  useEffect(() => {
    const handleOrientationChange = (e: MediaQueryListEvent) => {
      setIsPortrait(e.matches);
    };

    const portraitQuery = window.matchMedia("(orientation: portrait)");
    portraitQuery.addEventListener("change", handleOrientationChange);

    return () => {
      portraitQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsExpanded(true);
    }, 100);
  }, []);

  const handleClose = () => {
    setIsExpanded(false);

    setTimeout(() => {
      setIsAnimating(false);
      onClose();
    }, 430);
  };

  const nextImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={styles.modal_overlay}>
      <div
        className={`${styles.navigation_container} ${isAnimating ? styles.animate : ""} ${
          isExpanded ? styles.expanded : ""
        }`}
      >
        <div className={styles.navigation_top}>
          <div className={styles.close_container}>
            <img
              className={styles.icon_close}
              src={gallery_close}
              alt="Close"
              onClick={handleClose}
            />
          </div>
        </div>

        <div className={styles.container}>
          <div
            className={styles.gallery_images}
            style={
              isPortrait
                ? {
                    transform: `translateX(-${currentImage * 70}vw)`,
                  }
                : {
                    transform: `translateX(-${currentImage * 70}vh)`,
                  }
            }
          >
            {images.map((image, index) => (
              <img
                key={index}
                className={styles.image}
                src={image}
                alt={`Gallery item ${index}`}
              />
            ))}
          </div>
        </div>

        <div className={styles.navigation_bottom}>
          <div
            className={styles.arrow_container}
            style={
              currentImage === 0
                ? { backgroundColor: "#FFFFFF" }
                : { backgroundColor: "#d4d2d2" }
            }
          >
            {currentImage !== 0 && (
              <img
                className={`${styles.arrow} ${styles.left_arrow}`}
                src={gallery_left}
                alt="Previous"
                onClick={prevImage}
              />
            )}
          </div>
          <div className={styles.arrow_container}>
            <img
              className={`${styles.arrow} ${styles.right_arrow}`}
              src={gallery_right}
              alt="Next"
              onClick={nextImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalImageGallery;
