import React, { useEffect, useState } from "react";
import styles from "./SocialMedia.module.css";
import {
  logo_facebook,
  logo_instagram,
  logo_linkedin,
  facebook_working,
  instagram_working,
} from "utils/Assets";

const SocialMedia: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Update the state initially based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const elements = [
      { logo: styles.logo_facebook, working: styles.working_facebook },
      { logo: styles.logo_instagram, working: styles.working_instagram },
    ].map(({ logo, working }) => ({
      logo: document.getElementsByClassName(logo)[0] as HTMLImageElement,
      working: document.getElementsByClassName(working)[0] as HTMLImageElement,
    }));

    const toggleImage = (
      workingImage: HTMLImageElement,
      show: boolean,
      temporary = false
    ) => {
      workingImage.style.opacity = show ? "1" : "0";
      if (temporary) {
        workingImage.style.zIndex = show ? "10" : "1";
        if (show) {
          setTimeout(() => toggleImage(workingImage, false, true), 3000);
        }
      }
    };

    elements.forEach(({ logo, working }) => {
      if (!logo || !working) return;

      const events = isMobile
        ? { click: () => toggleImage(working, true, true) }
        : {
            mouseover: () => toggleImage(working, true),
            mouseout: () => toggleImage(working, false),
          };

      Object.entries(events).forEach(([event, handler]) => {
        logo.addEventListener(event, handler);
      });

      return () => {
        Object.entries(events).forEach(([event, handler]) => {
          logo.removeEventListener(event, handler);
        });
      };
    });
  }, [isMobile]);

  return (
    <div className={styles.container}>
      <div className={styles.instagram}>
        <img
          className={styles.working_instagram}
          src={instagram_working}
          alt="Instagram in work"
        />
        <img
          className={styles.logo_instagram}
          src={logo_instagram}
          alt="Logo for Instagram"
        />
      </div>
      <div className={styles.facebook}>
        <img
          className={styles.working_facebook}
          src={facebook_working}
          alt="Facebook in work"
        />
        <img
          className={styles.logo_facebook}
          src={logo_facebook}
          alt="Logo for Facebook"
        />
      </div>
      <div className={styles.linkedIn}>
        <a
          href="https://si.linkedin.com/in/sebastjan-rutart-s-p-grafi%C4%8Dno-oblikovanje-311105331"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={styles.logo_linkedIn}
            src={logo_linkedin}
            alt="Logo for LinkedIn"
          />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
