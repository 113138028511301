import React from "react";
import styles from "./Contact.module.css";
import Button from "components/Button/Button";
import { QR_koda, vprasaj_arrow_large } from "utils/Assets";

const Contact: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.kontakt}>
        <div className={styles.text}>
          <h2>Kontakt:</h2>
          <p>070 434 992</p>
          <p>oblikovanje@rutart.si</p>
          <p>rutart.si</p>
        </div>
        <img className={styles.qr} src={QR_koda} alt="QR code" />
      </div>
      <div className={styles.button}>
        <Button
          text={"Za portfolio"}
          image={vprasaj_arrow_large}
          subject={"Želim portfolio"}
        />
      </div>
    </div>
  );
};

export default Contact;
