import React from "react";
import styles from "./Experience.module.css";
import { logo_adobe, banner3 } from "utils/Assets";

const Experience: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.izkusnje}>
        <h2>
          Izkušnje
          <br />
          in strokovnost
        </h2>
        <p>
          Z več kot 15 leti izkušenj kot grafični in spletni oblikovalec v
          marketingu, kjer sem skrbel za celostno podobo podjetja, oblikoval
          promocijsko gradivo in upravljal spletno stran, zdaj kot samostojni
          podjetnik ponujam oblikovalske storitve za digitalne in tiskane
          medije.
          <br />
          Oblikoval sem plačilne kartice, zloženke, nagradne programe, priloge k
          računom, oglasne, spletne pasice, e-poštne kampanje in druge
          oglaševalske materiale.
          <br />
          Za mednarodna podjetja sem zasnoval embalažo za kozmetične in
          prehrambene izdelke.
          <br />
          Kot samostojni podjetnik sem popolnoma prilagodljiv, kar pomeni, da
          lahko nudim oblikovalske storitve, ki so natančno prilagojene zahtevam
          in časovnim okvirom vašega podjetja. Z veseljem se bom pogovoril o
          vaših potrebah in pričakovanjih ter skupaj z vami poiskal najboljšo
          rešitev.
        </p>
        <img className={styles.adobe} src={logo_adobe} alt="Adobe logo" />
      </div>

      <div className={styles.banner_container}>
        <img className={styles.banner} src={banner3} alt="Banner izkusnje" />
        <div className={styles.banner_text}>
          <p>Izberite</p>
          <p>oblikovanje,</p>
          <p>ki ustreza</p>
          <p>vašim</p>
          <p>potrebam</p>
          <p>in ciljem!</p>
        </div>
      </div>
    </div>
  );
};

export default Experience;
