import React from "react";
import styles from "./Footer.module.css";
import { logo_rutart } from "utils/Assets";

const Footer: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.line} />

      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h3>Kontaktni podatki</h3>
          <p>Sebastjan Rutart s.p.</p>
          <p>Oblikovanje za digitalni in družbeni prostor</p>
          <p>Mislinjska Dobrava 27, 2383, Šmartno pri Slovenj Gradcu</p>
          <p>TRR: SI 56 34000-1022812402</p>
        </div>
        <div className={styles.right}>
          <img className={styles.logo} src={logo_rutart} alt="Rutart logo" />
          <p>Sebastjan Rutart: Grafično oblikovanje</p>
          <p>Tisk na majice po naročilu</p>
          <p>Anže Fric: Postavitev in izdelava</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
