import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import { logo, logo_christmas } from "utils/Assets";

const Header: React.FC = () => {
  const [isChristmas, setIsChristmas] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  const handleLogoClick = () => {
    setShowTransition(true);
    setTimeout(() => {
      setIsChristmas(true);
      setShowTransition(false);
    }, 100); // Delay before showing christmas logo when main disappears
  };

  useEffect(() => {
    if (isChristmas) {
      const christmasTimeout = setTimeout(() => {
        setIsChristmas(false);
        setShowTransition(false);
      }, 5000); // Show Christmas logo for 5 seconds

      return () => clearTimeout(christmasTimeout);
    }
  }, [isChristmas]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Oblikovanje</h1>
        <p>ZA DIGITALNI IN DRUŽBENI PROSTOR</p>
      </div>

      <div className={styles.logo_container}>
        {isChristmas ? (
          <img
            className={`${styles.logo_christmas} ${showTransition ? styles.fade_out : styles.fade_in}`}
            src={logo_christmas}
            alt="Rutart main christmas logo"
          />
        ) : (
          <img
            className={`${styles.logo} ${showTransition ? styles.fade_out : ""}`}
            src={logo}
            alt="Rutart main logo"
            onClick={handleLogoClick}
          />
        )}
        <p className={styles.logo_text}>Hitro oblikovanje na daljavo</p>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export default Header;
