import React, { useState } from "react";
import styles from "./ImageGallery.module.css";
import { gallery_mobile_left, gallery_mobile_right } from "../../utils/Assets";

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);

  const nextImage = (images: string[]) => {
    setCurrentImage((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = (images: string[]) => {
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={styles.container}>
      {currentImage !== 0 && (
        <img
          className={`${styles.arrow} ${styles.left_arrow}`}
          src={gallery_mobile_left}
          alt="Arrow previous"
          onClick={() => prevImage(images)}
        />
      )}
      <div
        className={styles.gallery_images}
        style={{
          width: `calc(${100 * images.length}vw)`,
          transform: `translateX(-${currentImage * 100}vw)`,
        }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            className={styles.image}
            src={image}
            alt={`Gallery item ${index}`}
          />
        ))}
      </div>
      <img
        className={`${styles.arrow} ${styles.right_arrow}`}
        src={gallery_mobile_right}
        alt="Arrow next"
        onClick={() => nextImage(images)}
      />
    </div>
  );
};
export default ImageGallery;
